/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/register', (page, model, params, next) => page.render('register'));

app.get('/login', function(page, model, params, next) {
	if (__guard__(page.req != null ? page.req.headers : undefined, x => x.referer)) {
		model.set('_page.referer', __guard__(page.req != null ? page.req.headers : undefined, x1 => x1.referer));
	}

	return page.render('login');
});


function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}