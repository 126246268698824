const bg = [
    'https://images.pexels.com/photos/34090/pexels-photo.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/30732/pexels-photo-30732.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/139321/pexels-photo-139321.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1341/blue-abstract-glass-balls.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/370/lights-night-dark-abstract.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/48728/pexels-photo-48728.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/129743/pexels-photo-129743.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/28477/pexels-photo-28477.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1755/purple-abstract-blur-bokeh.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/36464/sky-clouds-clouds-form-cumulus-clouds.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/225225/pexels-photo-225225.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/230273/pexels-photo-230273.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/63574/pexels-photo-63574.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1752/trees-abstract-bokeh.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/3616/lights-dark-abstract-bokeh.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/230778/pexels-photo-230778.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1946/purple-abstract-haze-blur.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/3221/traffic-lights-night-abstract.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1079/abstract-blur-green-blurred.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/1744/lights-abstract-blur-orange.jpg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/237898/pexels-photo-237898.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb',
    'https://images.pexels.com/photos/240230/pexels-photo-240230.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb'
];

module.exports.bg = id => bg[id.charCodeAt(0) % bg.length];