/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class AllChats {
	init() {
		const {
            model
        } = this;

		const fn1 = function(a) {
			let arr = (model.root.get('_page.chats') || []);
			arr = (Array.from(arr).map((x) => x.id));
			return Array.from(arr).includes(a.id);
		};

		const fn2 = function(a, b) {
			let x = model.root.get(`_p.usermisc.chats.${b.id}.unread`);
			x = (x && x.length) || 0;
			let y = model.root.get(`_p.usermisc.chats.${a.id}.unread`);
			y = (y && y.length) || 0;
			return x - y;
		};

		const sort = model.filter(model.root.at('chat'), fn1).sort(fn2);
		// sort = model.sort(model.root.at('chat'), fn2)
		return model.ref('chats', sort);
	}
}
		// model.ref 'chats', model.root.at('_page.chats')

class Chatroom {

	constructor() {
		this.chatUserId = this.chatUserId.bind(this);
		this.notificationUserId = this.notificationUserId.bind(this);
		this.setCommentChannelRead = this.setCommentChannelRead.bind(this);
		this.setCommentsRead = this.setCommentsRead.bind(this);
		this.insertedIntoIdList = this.insertedIntoIdList.bind(this);
		this.scrollToEnd = this.scrollToEnd.bind(this);
		this.keydown = this.keydown.bind(this);
	}

	destroy() {
		const id = this.model.get('room.id');
		this.model.root.removeAllListeners('insert', '_page.chatitems');
		this.model.root.removeAllListeners('insert', '_page.chatitemids');
		this.model.root.removeAllListeners('insert', '_p.usermisc.chats.#{id}.unread');
		return this.model.root.removeAllListeners('insert', '_p.usermisc.chats.unreadall');
	}

	create() {
		const id = this.model.get('room.id');
		this.model.root.on('insert', '_page.chatUserIdsByRoom.*', this.chatUserId);
		this.model.root.on('insert', '_page.chatitems', this.scrollToEnd);
		this.model.root.on('insert', '_page.chatitemids', this.insertedIntoIdList);
		this.model.root.on('insert', '_page.notificationschatuser', this.notificationUserId);
		this.model.root.on('insert', `_p.usermisc.chats.${id}.unread`, this.setCommentsRead);
		this.model.root.on('insert', "_p.usermisc.chats.unreadall", this.setCommentChannelRead);
		this.scrollToEnd(true);
		this.setCommentsRead();
		return this.setCommentChannelRead();
	}

	chatUserId(idx, ids) {
		console.log('chatUserId', arguments);
		return (() => {
			const result = [];
			for (var id of Array.from(ids)) {
				var needle;
				if ((needle = id, !Array.from(this.model.root.get('_p.userids')).includes(needle))) {
					result.push(this.model.root.push('_p.userids', id));
				} else {
					result.push(undefined);
				}
			}
			return result;
		})();
	}

	notificationUserId(idx, ids) {
		return Array.from(ids).map((id) =>
			this.model.root.push('_page.chatuserids', id));
	}

	setCommentChannelRead() {
		// remove the channel from the unread list of channels
		const id = this.model.get('room.id');
		const unreadall = this.model.root.get("_p.usermisc.chats.unreadall") || [];
		const idx = unreadall.indexOf(id);
		if (idx !== -1) {
			return this.model.root.remove("_p.usermisc.chats.unreadall", idx);
		}
	}

	setCommentsRead() {
		// delete the unread message list for this channel
		const id = this.model.get('room.id');
		return this.model.root.del(`_p.usermisc.chats.${id}.unread`);
	}

	// in sc-fetch-and-subscribe this list gets inserted
	// this list isn't the actual list that we use to fetch the items though,
	// but the inverted list is. Here we add ids to the inverted list
	insertedIntoIdList(idx, ids) {
		return this.model.root.set('_page.chatitemidsreverse', this.model.root.get('_page.chatitemids').slice().reverse());
	}

	scrollToEnd(idx) {
		if (idx) {
			return this.messages.scrollTo(0, this.messages.scrollHeight);
		}
	}

	addChatUser(e) {
		e.preventDefault();
		this.hideAddUserDialog(e);
		const item = this.model.get('addChatUserItem');
		let users = __guard__(item != null ? item.data : undefined, x => x.match);
		let re = /@/g;

		const getAll = s => {
			let res;
			if (s == null) { s = ''; }
			re = /(?:\s|^)@(\w+)/g;

			return res = (() => {
				let m;
				const result = [];
				while ((m = re.exec(s))) {
				//term: m[1], start: m.index + (m[0].length - m[1].length), end: m.index + m[0].length
					result.push(m[1]);
				}
				return result;
			})();
		};

		users = getAll(item != null ? item.data : undefined);
		if (users != null ? users.length : undefined) {
			return util.toServer(this.model.root.at('_p.methods'), 'add-chatusers', { room: this.model.get('room.id'), users });
		}
	}


	showAddUserDialog(e) {
		this.model.set('addChatUserItem', { data: '' });
		return this.model.set('showingAddUserDialog', 1);
	}

	hideAddUserDialog(e) {
		e.preventDefault();
		return this.model.del('showingAddUserDialog');
	}

	saveComment(e) {
		const item = this.model.get('item');
		const data = this.model.get('item.data');
		const photos = this.model.get('item.photospv');
		this.model.del('item');
		e.preventDefault();
		//model.add 'chatitems', { }
		return util.toServer(this.model.root.at('_p.methods'), 'add-chatitem', { room: this.model.get('room.id'), data, photos });
	}

	keydown(ev) {
		if ((ev != null ? ev.keyCode : undefined) === 13) {
			return this.saveComment(ev);
		}
	}

	toggleEmojis() {
		if (this.model.get('showEmojis')) {
			return this.model.del('showEmojis');
		} else {
			return this.model.set('showEmojis', true);
		}
	}

	showEmojis() {
		return this.model.set('showEmojis', true);
	}

	hideEmojis() {
		return this.model.del('showEmojis');
	}

	deletePhoto(i) {
		return this.model.remove('item.photospv', i);
	}
}

app.component('chat:chatroom', Chatroom);
app.component('chat:all', AllChats);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}