/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/@:username', (page, model, params, next) => model.rpc('public-profile', { username: params.username }, function(err) {
    if (err) {
        return page.render('public-profile:not-found');
    } else {
        return page.render('public-profile');
    }
}));

app.get('/@:username/followers', (page, model, params, next) => model.rpc('public-profile-followers', { username: params.username }, function(err) {
    if (err) {
        return page.render('public-profile:not-found');
    } else {
        return page.render('public-profile:followers');
    }
}));

app.get('/@:username/following', (page, model, params, next) => model.rpc('public-profile-following', { username: params.username }, function(err) {
    if (err) {
        return page.render('public-profile:not-found');
    } else {
        return page.render('public-profile:followers');
    }
}));

app.get('/@:username/videos', (page, model, params, next) => model.rpc('public-profile-videos', { username: params.username }, function(err) {
    if (err) {
        return page.render('public-profile:not-found');
    } else {
        return page.render('public-profile');
    }
}));

