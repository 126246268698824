/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const conf = require('kansalaiskeskustelu-conf').common;

const push = function(userids, from) {
	let needle;
	if (from && (needle = from, !Array.from(userids.get()).includes(needle))) {
		return userids.push(from);
	}
};

var util = {

	toServer(user, method, data, cb) {
		if (data == null) { data = {}; }
		return user.setNull("methods", {}, err => user.del(`methods.${method}`, err => user.set(`methods.${method}`, data, function(err) {
            if (cb) { return cb(); }
        })));
	},

	inViewport: el => {
		if (el) {
			const r = el.getBoundingClientRect();

			return (
				(r.left >= 0) &&
				(r.top > 60) &&
				(r.bottom <= (window.innerHeight || document.documentElement.clientHeight)) &&
				(r.right <= (window.innerWidth || document.documentElement.clientWidth))
			);
		}
	},

	aboveViewport: el => {
		if (el) {
			const r = el.getBoundingClientRect();
			return r.top < 0;
		}
	},

	redirect(app, page, url) {
		try {
			if (__guard__(app != null ? app.history : undefined, x => x.push)) {
				return app.history.push(url);
			} else if (__guard__(page != null ? page.res : undefined, x1 => x1.redirect)) {
				return page.res.redirect(url);
			}
		} catch (err) {
			return console.error('[ util.redirect ]', url, ':', err);
		}
	},

	validateUsername(username, cb) {
		if (username) {
			const r = new RegExp(conf.usernamePattern);
			if (r.test(username)) {
				const userQ = this.model.query('auths', {
					'$text': { $search: username, $field: "local.username", $limit: 1 }
				});

				const bannedQ = this.model.root.query('misc', { banned: { $in: [ username ] }});
				return this.model.root.fetch(userQ, bannedQ, err => {
					let s;
					const user = userQ.get();
					const banned = bannedQ.get();
					if ((user != null ? user.length : undefined) || (banned != null ? banned.length : undefined)) {
						s = ((this.app != null ? this.app.proto.t.call(this, "Username") : undefined) || "Käyttäjätunnus") + ` ${username} ` + ((this.app != null ? this.app.proto.t.call(this, "exists") : undefined) || "on jo olemassa");
						this.model.root.set('_page.usernameValidation', { error: true, msg: s });
						if (cb) { return cb({ error: true, msg: s }); }
					} else {
						s = (this.app != null ? this.app.proto.t.call(this, "Username is ok") : undefined) || "Käyttäjätunnus on ok";
						this.model.root.set('_page.usernameValidation', { ok: true, msg: s });
						if (cb) { return cb({ ok: true, msg: s }); }
					}
				});
			} else {
				if (username.length < 3) {
					this.model.root.del('_page.usernameValidation');
					if (cb) { return cb({ error: true, msg: 'At least 3 chars' }); }
				} else {
					const s = (this.app != null ? this.app.proto.t.call(this, 'Username can only contain these characters: a-z 0-9 _ .') : undefined) || "Käyttäjätunnus voi sisältää näitä merkkejä: a-z 0-9 _ .";
					this.model.root.set('_page.usernameValidation', { error: true, msg: s });
					if (cb) { return cb({ error: true, msg: s }); }
				}
			}
		} else {
			this.model.root.del('_page.usernameValidation');
			if (cb) { return cb({ error: true, msg: 'Käyttäjätunnus puuttui.' }); }
		}
	},

	// is this a plain retweet of some of my statuses
	myRetweet(item, me) { return (item.shareuser === me) && !item.html; },

	updateUseridsForComments(userids, comments) {
		if (comments && Array.isArray(comments)) {
			return (() => {
				const result = [];
				for (var comment of Array.from(comments)) {
					var id, needle;
					if ((needle = comment.from, !Array.from(userids.get()).includes(needle))) {
						push(userids, comment.from);
					}

					if (comment.likes) {
						for (id of Array.from(comment.likes)) {
							var needle1;
							if ((needle1 = id, !Array.from(userids.get()).includes(needle1))) {
								push(userids, id);
							}
						}
					}
							
					if (comment.shares) {
						for (id of Array.from(comment.shares)) {
							var needle2;
							if ((needle2 = id, !Array.from(userids.get()).includes(needle2))) {
								push(userids, id);
							}
						}
					}

					if (comment.replies) {
						result.push(util.updateUseridsForComments(userids, comment.replies));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	},

	updateUseridsPlain(items, path) {
		if (path == null) { path = '_p.userids'; }
		const userids = this.at(path);

		if (items) {
			return (() => {
				const result = [];
				for (var id of Array.from(items)) {
					var needle;
					if (id && (needle = id, !Array.from(userids.get()).includes(needle))) {
						result.push(push(userids, id));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	},

	createReadcountDocId(userId, item) {
		if (item != null ? item.created : undefined) {
			const d = new Date(item != null ? item.created : undefined);
			return `${userId}-${d.getUTCFullYear()}-${d.getUTCMonth()}`;
		}
	},

	updateAllFromPosts(userId, items, sharedids_path, userids_path, readcountids_path) {
		if (items == null) { items = []; }
		if (sharedids_path == null) { sharedids_path = '_p.sharedids'; }
		if (userids_path == null) { userids_path = '_p.userids'; }
		if (readcountids_path == null) { readcountids_path = '_p.readcountids'; }
		const readcountids = this.at(readcountids_path);
		const sharedids = this.at(sharedids_path);
		const userids = this.at(userids_path);

		readcountids.setNull([]);
		sharedids.setNull([]);
		userids.setNull([]);

		const p = function(id) {
			if (id) {
				let needle;
				if ((needle = id, !Array.from(sharedids.get()).includes(needle))) {
					return sharedids.push(id);
				}
			}
		};

		if (items.length) {
			return (() => {
				const result = [];
				for (var item of Array.from(items)) {

					if (item) {var needle, needle1;
					
						var docid = util.createReadcountDocId(userId, item);
						p(item.id);
						p(item.share);
						p(item.tweet != null ? item.tweet.ref : undefined);

						if (docid && (needle = docid, !Array.from(readcountids.get()).includes(needle))) {
							readcountids.push(docid);
						}

						// userids
						if ((needle1 = item.from, !Array.from(userids.get()).includes(needle1))) {
							push(userids, item.from);
						}

						if (item.comments) {
							result.push(util.updateUseridsForComments(userids, item.comments));
						} else {
							result.push(undefined);
						}
					}
				}
				return result;
			})();
		}
	},

	updateNotifStatusIds(items) {
		const statusids = this.at('_p.sharedids');

		if (items) {
			return (() => {
				const result = [];
				for (var item of Array.from(items)) {
					push(statusids, (typeof item === 'string' ? item : (item != null ? item.statusId : undefined)));
					if (item != null ? item.quotingStatusId : undefined) {
						result.push(push(statusids, item.quotingStatusId));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	},

	// 'path' keeps track of what tasks are being subscribed into
	// notif.statusId holds the task id (despite the name)
	updateAllFromNotifs(notifs, sharedids_path, userids_path) {
		if (notifs == null) { notifs = []; }
		if (sharedids_path == null) { sharedids_path = '_p.sharedids'; }
		if (userids_path == null) { userids_path = '_p.userids'; }
		const sharedids = this.at(sharedids_path);
		const userids = this.at(userids_path);

		return (() => {
			const result = [];
			for (var notif of Array.from(notifs)) {
				if (notif) {
					var needle, needle1;
					if (notif.statusId && (needle = notif.statusId, !Array.from(sharedids.get()).includes(needle))) {
						push(sharedids, notif.statusId);
					}

					if (notif.quotingStatusId && (needle1 = notif.quotingStatusId, !Array.from(sharedids.get()).includes(needle1))) {
						push(sharedids, notif.quotingStatusId);
					}

					if (notif.from != null ? notif.from.length : undefined) {
						result.push(Array.from(notif.from).map((from) =>
							push(userids, from)));
					} else {
						result.push(undefined);
					}
				} else {
					result.push(undefined);
				}
			}
			return result;
		})();
	},

	updateSharedIds(items, path) {
		if (path == null) { path = '_p.sharedids'; }
		const sharedids = this.at(path);

		if (items) {
			return (() => {
				const result = [];
				for (var item of Array.from(items)) {
					var needle, needle1;
					if ((item != null ? item.share : undefined) && (needle = item.share, !Array.from(sharedids.get()).includes(needle))) {
						sharedids.push(item.share);
					}
					if (__guard__(item != null ? item.tweet : undefined, x => x.ref) && (needle1 = item.tweet.ref, !Array.from(sharedids.get()).includes(needle1))) {
						result.push(sharedids.push(item.tweet.ref));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	},


	removeUnreads(items) {
		let i, unread;
		const unreadsM = this.at('_p.usermisc.unreads');
		const unreadmeM = this.at('_p.usermisc.unreadme');
		const unreads = unreadsM.get();
		const unreadme = unreadmeM.get();
		const itemIds = (Array.from(items).filter((item) => (item != null ? item.status : undefined) === 'published').map((item) => item.id));
		if (unreads) {
			for (i = unreads.length - 1; i >= 0; i--) {
				unread = unreads[i];
				if (!Array.from(itemIds).includes(unread)) {
					unreadsM.remove(i);
				}
			}
		}
		if (unreadme) {
			return (() => {
				const result = [];
				for (i = unreadme.length - 1; i >= 0; i--) {
					unread = unreadme[i];
					if (!Array.from(itemIds).includes(unread)) {
						result.push(unreadmeM.remove(i));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	},

	setUnseen(items, disconnectedTime) {
		const path = '_p.unseen';

		const now = Date.now();
		if (items) {
			return (() => {
				const result = [];
				for (var item of Array.from(items)) {
					if (item) {
						// if the item's creation date was before we disconnected last time, it means
						// that the item was already fetched. in that case we don't want to set it 
						// as unseen.
						if ((item != null ? item.created : undefined) && (item.created < disconnectedTime)) {
							continue;
						}

						// hack: we need to also check if the last child of the item list
						// is outside the screen -- this ensures that the items we are receiving
						// may be above the viewport. If the lastchild it's on the screen, 
						// the item we received won't be above the screen.
						var el = document.getElementById(`s-${item.id}`);
						var lastChild = __guard__(el != null ? el.parentNode : undefined, x => x.lastElementChild);

						if (lastChild && el && util.aboveViewport(el, lastChild)) {
							result.push(this.set(`${path}.${item.id}`, 1));
						} else {
							result.push(undefined);
						}
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	}
};


module.exports = util;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}