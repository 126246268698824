/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/admin-profile/:id', function(page, model, params, next) {
	if (!model.get('_p.user_pvt.admin')) {
		return page.render('error:404');
	} else {
		const user = model.at(`auths_pvt.${params.id}`);
		const user_public = model.at(`auths.${params.id}`);
		return model.subscribe(user, user_public, function(err) {
			model.ref('_page.profile', user);
			model.ref('_page.profile_public', user_public);
			return page.render('admin-profile');
		});
	}
});
