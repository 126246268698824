/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Notifications {
  constructor() {
    this.scrollHandler = this.scrollHandler.bind(this);
    this.setupMutationObserver = this.setupMutationObserver.bind(this);
  }

  destroy(model) {
    return document.removeEventListener('scroll', this.scrollHandler);
  }

  create(model) {
    this.setupMutationObserver(document.getElementById('notif-list'));
    this.overflowAnchorEnabled = window.getComputedStyle(document.body).overflowAnchor === 'auto';
    return document.addEventListener('scroll', this.scrollHandler);
  }

  init(model) {

    this.removeReadNotifs();

    model.root.on('insert', '_page.notifs', (index, arr) => {
      return util.updateAllFromNotifs.call(model.root, arr);
    });

    return model.root.on('insert', 'notif.*.from', (docid, index, arr) => {
      return util.updateUseridsPlain.call(model.root, arr);
    });
  }

  scrollHandler() {
    if (window.scrollY === 0) { 
      this.removeReadNotifs();
    }

    const items = this.model.root.get("_p.usermisc.notifur") || [];

    return (() => {
      const result = [];
      for (let idx = 0; idx < items.length; idx++) {
        var itemId = items[idx];
        if (util.inViewport(document.getElementById(`s-${itemId}`))) {
          result.push(this.model.root.remove("_p.usermisc.notifur", idx));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  lastn(length, i, n) {
    if (n == null) { n = 5; }
    return (length <= n) || ((length - i) <= n);
  }

  firstn(length, i, n) {
    if (n == null) { n = 5; }
    return ((length <= n) && (i === 0)) || ((length - i) === n);
  }

  removeReadNotifs() {
    return this.model.root.set('_p.usermisc.notifur', []);
  }

  setupMutationObserver(target) {

    const scrollr = node => {
      return () => this.scrollRightAmount(node);
    };

    const observer = new MutationObserver(mutations => {
      return mutations.forEach(mutation => {
        if (__guard__(mutation != null ? mutation.addedNodes : undefined, x => x.length)) {
          for (var node of Array.from((mutation != null ? mutation.addedNodes : undefined))) {
            // this should be the first LI child of the target node (UL)
            //if node.nodeName is 'LI' and node.parentNode is target
            //  console.log node
            //if node.nodeName is 'LI' and node.parentNode is target and node.dataset?.from isnt me and node.classList.contains('unseen')
            if ((node.nodeName === 'LI') && (node.parentNode === target)) {
              var b = node.getBoundingClientRect();
              //console.log node, node is node.parentNode.firstElementChild, b.top, node.dataset?.from isnt me, window.pageYOffset
              if ((node.nodeName === 'LI') && (node.parentNode === target) && ((b.top < 65) || (node === node.parentNode.firstElementChild))) {                
                if (this.overflowAnchorEnabled && (b.top < 0)) { return; }
                this.scrollRightAmount(node);

                var imgs = node.querySelectorAll('img');
                if (imgs != null ? imgs.length : undefined) {
                  for (var img of Array.from(imgs)) {
                    img.addEventListener('load', scrollr(node));
                  }
                }
              }
            }
          }
        }
      });
    });

    if (target) {
      return observer.observe(target, { childList: true, subtree: true });
    }
  }

  // scroll when a new status arrivess
  scrollRightAmount(el) {
    const from = (el.h || 0);
    const b = el.getBoundingClientRect();
    const h = b.height - from;
    el.h = b.height;
    if (h) {
      return window.scrollBy(0, h);
    }
  }
}

app.component('notifications', Notifications);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}