/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Home {
	destroy(model) {
		// save scroll position
		// find the first status that is visible
		const parent = document.getElementById('status-list');
		if (parent != null ? parent.children : undefined) {
			return (() => {
				const result = [];
				for (var el of Array.from(parent.children)) {
					if (util.inViewport(el)) {
						var b = el.getBoundingClientRect();
						window.savedStatusElement = [ el.id, b.top ];
						break;
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	}

	create(model) {
		if (window.savedStatusElement && (__guard__(this.page != null ? this.page.params : undefined, x => x.previous) !== '/')) {
			//console.log window.savedStatusElement
			// restore scroll position
			// we have to set this so that aamu-derby will not scroll
			this.page.params.disableScrolling = true;
			const el = document.getElementById(window.savedStatusElement[0]);
			if (el) {
				el.scrollIntoView(true); 
				window.scrollBy(0, - window.savedStatusElement[1]);
				return delete window.savedStatusElement;
			}
		} else if (window.pageYOffset === 0) { 
			return this.page.emit('setAllSeen');
		}
	}

	loginMobile(e, which) {
		// iOS
		if (typeof (typeof window !== 'undefined' && window !== null ? window.webkit : undefined) !== 'undefined') {
			e.preventDefault();
			return __guard__(window.webkit.messageHandlers != null ? window.webkit.messageHandlers.iosapp : undefined, x => x.postMessage("login-started"));		
		}
	}
}

app.component('home', Home);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}