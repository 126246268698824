/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const UTIL = require('../util');


app.post('/edit-profile', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  const userO = model.root.get('_p.user');
  const user = model.root.at('_p.user');
  if (!userO) {
    return next();
  } else {
    if (!params.body.fullname) {
      return model.toast('error', 'Name cannot be empty...');
    } else {
      return user.set('local.fullname', params.body.fullname, () => {
        user.set('local.descMd', params.body.description, () => {});
        return UTIL.toServer(this.model.root.at('_p.methods'), 'profile-description', { description: params.body.description }, () => {
          return UTIL.redirect(app, page, `/@${user.get('local.username')}`);
        });
      });
    }
  }
});

app.get('/edit-profile', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  const user = model.root.get('_p.user');
  if (!user) {
    return next();
  } else {
    return page.render('edit-profile:info');
  }
});

app.get('/password-reset', (page, model, params, next) => page.render('login:password-reset'));

app.get('/settings', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-main');
});

app.get('/settings/admin-usernames', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  if (model.root.get('_p.user_pvt.admin')) {
    const q = model.query('auths_pvt', { $exists: 'local.username2' });
    return q.subscribe(function(err) {
      model.ref('_page.admin_usernames',q);
      return page.render('edit-profile:settings-admin-usernames');
    });
  } else { return next('error...'); }
});

app.get('/settings/password', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-password');
});

app.get('/settings/email', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-email');
});

app.get('/settings/username', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-username');
});

app.get('/settings/add-email', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-add-email');
});

app.post('/settings/email', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  const user = model.root.at('_p.user');
  if (user != null ? user.get() : undefined) {
    const old = user.get('local.email');
    if (__guard__(params != null ? params.body : undefined, x => x.email) && (old !== params.body.email)) {
      UTIL.toServer(this.model.root.at('_p.methods'), 'change-email', { email: params.body.email });
      model.flash('success', 'Sending confirmation email...');
    } else if (__guard__(params != null ? params.body : undefined, x1 => x1.action) === 'resend') {
      UTIL.toServer(this.model.root.at('_p.methods'), 'email-changed', {});
      model.flash('success', 'Sending confirmation email...');
    } else if (!__guard__(params != null ? params.body : undefined, x2 => x2.email)) {
      model.flash('error', 'You can\'t remove the email address (you can change it).');
    }
  }

  return UTIL.redirect(app, page, '/settings/email');
});

app.get('/settings/delete', function(page, model, params, next) {
  if (!model.get('_session.loggedIn')) {
    return UTIL.redirect(app, page, "/");
  }

  return page.render('edit-profile:settings-delete-account');
});


function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}