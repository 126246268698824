/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let Profile;
const md5 = require("md5");
const util = require("../util");

module.exports = (Profile = (function() {
  Profile = class Profile {
    static initClass() {
  
      this.prototype.name = 'profile';
  
      this.prototype.fullname = {
        get() {
          const user = this.model.root.get('_p.user');
          return __guard__(user != null ? user.local : undefined, x => x.fullname) || '?';
        },
        set() {}
      };
    }

    create() {
      return window.m = this.model.root;
    }

    defaultpic(id) {
      if (!id) { id = this.model.root.get('_p.user.id') || '...'; }
      return `//www.gravatar.com/avatar/${md5(id)}?d=identicon`;
    }

    username() {
      const user = this.model.root.get('_p.user');
      return __guard__(user != null ? user.local : undefined, x => x.username) || '';
    }

    fullname() {
      const user = this.model.root.get('_p.user');
      return __guard__(user != null ? user.local : undefined, x => x.fullname) || '?';
    }

    pic() {
      const user = this.model.root.get('_p.user');
      if (__guard__(user != null ? user.local : undefined, x => x.photo)) {
        return user.local.photo;
      } else if (__guard__(__guard__(__guard__(user != null ? user.twitter : undefined, x3 => x3.photos), x2 => x2[0]), x1 => x1.value)) {
        return (user.twitter.photos != null ? user.twitter.photos[0].value : undefined);
      } else if (__guard__(user != null ? user.facebook : undefined, x4 => x4.id)) {
        return `https://graph.facebook.com/${user.facebook.id}/picture?width=48&height=48`;
      } else {
        return this.defaultpic();
      }
    }

    email() {
      const user = this.model.root.get('_p.user_pvt');
      return __guard__(user != null ? user.local : undefined, x => x.email);
    }

    description(md) {
      if (md == null) { md = false; }
      const user = this.model.root.get('_p.user');
      if (md) { return __guard__(user != null ? user.local : undefined, x => x.descMd); } else { return __guard__(user != null ? user.local : undefined, x1 => x1.description); }
    }

    idx() {
      const user = this.model.root.get('_p.user');
      return (user != null ? user.id : undefined);
    }

    pending() {
      const user = this.model.root.get('_p.user_pvt');
      return __guard__(user != null ? user.local : undefined, x => x.status) === 'pending-confirm';
    }

    admin() {
      const user = this.model.root.get('_p.user_pvt');
      return (user != null ? user.admin : undefined);
    }

    error(json) {
      return this.model.toast('error', json.error);
    }

    uploadedProfilePhoto(json) {
      return this.model.root.set('_page.misc.crop', json);
    }

    uploadedProfileHeader(json) {
      return this.model.root.set('_p.user.local.header', json.url);
    }

    profilePhotoDeleted() {
      return util.toServer(this.model.root.at('_p.methods'), 'profile-photo-deleted');
    }

    cropped(e, data) {
      e.preventDefault();
      this.model.root.set('_page.cropping', true);
      return util.toServer(this.model.root.at('_p.methods'), 'crop', data);
    }

    isConfirmed() { return this.model.root.get('_p.user_pvt.local.status') === 'registered'; }

    isEnabled() { return !this.model.root.get('_p.user_pvt.local.suspended'); }

    isLoggedIn() { return !!this.model.root.get('_session.loggedIn'); }
  };
  Profile.initClass();
  return Profile;
})());

Profile.prototype.fullname.set = function() {};
Profile.prototype.description.set = function() {};

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}