/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.post('/appeal', function(page, model, params, next) {
	if (__guard__(params != null ? params.body : undefined, x => x.appeal)) {
		util.toServer(this.model.root.at('_p.methods'), 'appeal-send', { text: __guard__(params != null ? params.body : undefined, x1 => x1.appeal) });
		this.model.toast('success', 'OK, appeal sent!');
	}

	return util.redirect(app, page, "/appeal");
});

app.get('/appeal', function(page, model, params, next) {
	const userId = model.get('_session.userId');
	const q = model.query('appeals', { userid: userId, status: 'active' });
	return q.subscribe(function(err) {
		if (__guard__(q.get(), x => x.length)) {
			model.set('_page.appeal', q.get()[0]);
		}

		return page.render('appeal');
	});
});


function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}