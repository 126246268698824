/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/saannot', (page, model, params, next) => page.render('static:saannot'));

app.get('/yksityisyys', (page, model, params, next) => page.render('static:yksityisyys'));

app.get('/sivustosta', (page, model, params, next) => page.render('static:sivustosta'));

