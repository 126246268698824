/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/search', function(page, model, params, next) {

	if (__guard__(params != null ? params.query : undefined, x => x.search)) {
		model.set('_p.user.search', params.query.search);
	// if we are coming with a back button, use the old search term
	} else if (!params.backbutton) {
		model.set('_p.user.search', '');
	}

	return page.render('search');
});

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}