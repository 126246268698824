/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const UTIL = require('../util');
const app = require('../index');

app.get('/tunnistaudu', (page, model, params, next) => page.render('tunnistaudu'));

