/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class StatusShare {

	constructor() {
		this.cancel = this.cancel.bind(this);
		this.keydown = this.keydown.bind(this);
	}

	show(e, status, readcount, commentPath) {
		if (commentPath == null) { commentPath = ''; }
		e.preventDefault();
		e.stopPropagation();

		// if status.path is set, this status is actually a comment
		if ((status != null ? status.path : undefined) && !commentPath) {
			commentPath = status.path;
		}

		// If status.parent is set, this status is actually a comment
		// and the status.parent refers to the status, that is comment
		// is attached to.
		// In this case we want to use the status' id, not the comment's id.
		//statusId = status.parent or status.id

		this.model.set('commentPath', commentPath);
		this.model.set('text', '');
		this.model.set('status', status);
		this.model.set('readcount', readcount);
		return this.model.set('isShared', this.isShared());
	}

	cancel(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.model.del('status');
		return this.hideEmojis();
	}


	toggleEmojis() {
		if (this.model.get('showEmojis')) {
			return this.model.del('showEmojis');
		} else {
			return this.model.set('showEmojis', true);
		}
	}

	showEmojis() {
		return this.model.set('showEmojis', true);
	}

	hideEmojis() {
		return this.model.del('showEmojis');
	}

	keydown(ev) {
		const key = ev.keyCode || ev.which;
		if (key === 27) {
			return this.cancel(ev);
		} else if ((ev.ctrlKey || ev.metaKey) && (key === 13)) {
			return this.submit(ev);
		}
	}

	isShared() {
		return this.model.get("readcount.shared");
	}

	shareOrUnshare() {
		let o;
		const userId = this.model.root.get('_session.userId');
		const user = this.model.root.at('_p.user');
		const status = this.model.get('status');
		const statusId = status.parent || status.id;
		const text = this.model.get('text');
		const isShared = this.isShared();
		const commentPath = this.model.get('commentPath');

		// share
		if (!isShared) {
			o = { id: statusId, text };
			if (commentPath) {
				o.comment = commentPath;
			}
			return util.toServer(this.model.root.at('_p.methods'), 'share', o, this.cancel);
		// unshare
		} else { 
			o = { id: statusId };
			if (commentPath) {
				o.comment = commentPath;
			}
			console.log(o);
			util.toServer(this.model.root.at('_p.methods'), 'unshare', o, this.cancel);
			return;
		}
	}

	submit(e) {
		e.preventDefault();
		e.stopPropagation();
		return this.shareOrUnshare();
	}
}


app.component('status-share', StatusShare);
