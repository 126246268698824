/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');
const StatusList = require('./status-list');
const conf = require('kansalaiskeskustelu-conf/common');

class StatusHome {
	create(model, dom) {
		// scroll/focus to the right location
		if (__guard__(__guard__(__guard__(dom != null ? dom.controller : undefined, x2 => x2.page), x1 => x1.params), x => x.hash)) {
			let el;
			const hash = __guard__(__guard__(__guard__(dom != null ? dom.controller : undefined, x5 => x5.page), x4 => x4.params), x3 => x3.hash);
			// nc = write new comment
			if (hash === '#nc') {
				el = document.querySelector('#comment-edit-wrap textarea');
				if (el) {
					el.focus();
				}
			// n = scroll to first new comment
			} else if (hash === '#n') {
				el = document.querySelector('.unviewed');
				console.log(el);
				if (el) {
					el.scrollIntoView(true);
					window.scrollBy(0, -67);
				}
			} else { 
				el = document.getElementById(hash.substring(1));
				if (el) {
					el.classList.add('target');
				}
			}
		}
					
		// headroom, header hider
		const Headroom = require('headroom.js');
		const headroom  = new Headroom(document.getElementById('header'), { tolerance: { down : 10, up : 20 }, offset : 150 });
		return headroom.init();
	}

	prepend(url) {
		if ((url != null ? url.indexOf('http') : undefined) !== 0) {
			return `https:${url}`;
		} else {
			return url;
		}
	}

	twitterImage(url) {
		return url.indexOf('https://pbs.twimg.com') === 0;
	}

	safeImageIfTwitter(url) {
		if (this.twitterImage(url)) {
			return `https://safe.chttr.co/${this.safeImage(url)}`;
		}
	}

	safeImage(url) {
		const urlLower = (url && url.toLowerCase()) || '';
		if (urlLower.indexOf('http') === 0) {
			try {
				if (decodeURI(url).indexOf('%') !== -1) { return encodeURIComponent(url); } else { return encodeURIComponent(encodeURI(decodeURI(url))); }
			} catch (err) {
				console.error(err, url);
				return '';
			}
		} else {
			return '';
		}
	}
}



class Status {
	constructor() {
		this.keydown = this.keydown.bind(this);
		this.sharedComment = this.sharedComment.bind(this);
		this.hideLinkHoverCard = this.hideLinkHoverCard.bind(this);
		this.showLinkHoverCard = this.showLinkHoverCard.bind(this);
		this.setupLinkHoverCards = this.setupLinkHoverCards.bind(this);
	}

	destroy() {
		return this.model.removeAllListeners('change', 'status.*');		
	}

	init() {
		this.model.ref('statusreal', this.model.at('status'));

		const userId = this.model.root.get('_p.user.id');
		const statusId = this.model.get('statusreal.id');
		const status = this.model.get('statusreal');

		const parent = this.model.get('statusreal.parent');
		const commentPath = this.model.get('statusreal.path');

		if (parent) {
			if (this.model.root.get(`items.${parent}`)) {
				this.model.ref("parent", this.model.root.at(`items.${parent}`));
			} else if (this.model.root.get(`items2.${parent}`)) {
				this.model.ref("parent", this.model.root.at(`items2.${parent}`));
			} else if (this.model.root.get(`items3.${parent}`)) {
				this.model.ref("parent", this.model.root.at(`items3.${parent}`));
			}
		}

		// set readcount if not set
		if (!this.model.get('readcount')) {
			const date = new Date(parent ? this.model.get('statusreal.parent.created') : this.model.get('statusreal.created'));
			const docid = `${userId}-${date.getUTCFullYear()}-${date.getUTCMonth()}`;
			const realStatusId = parent || statusId;

			this.model.set('rcdoc', docid);

			// set the correct ref to the readcount doc
			const p = parent ? `readcount.${docid}.${realStatusId}.${commentPath}` : `readcount.${docid}.${realStatusId}`;
			return this.model.ref('readcount', this.model.root.at(p));
		}
	}

	create() {
		setTimeout(this.setupLinkHoverCards, 200);
		this.model.on('change', 'status.html', (() => this.setupLinkHoverCards()), 200);
		
		if (window.location.hash) {
			this.page.params.disableScrolling = true;
			const el = document.getElementById(window.location.hash.substring(1));
			if (el) {
				el.scrollIntoView(true); 
				return window.scrollBy(0, -67);
			}
		}
	}

	encodeUrl() {
		const from = this.model.get('status.from');
		const url = this.model.get('status.url');
		const username = this.model.root.get(`auths.${from}.local.username`);
		return encodeURIComponent(`${conf.domain}/@${username}/${url}`);
	}

	play() {
		this.model.set('playing', true);
		return document.addEventListener('keydown', this.keydown, true);
	}

	stop() {
		return this.model.del('playing');
	}

	keydown(e) {
		const key = e.keyCode || e.which;
		if (key === 27) {
			e.stopPropagation();
			document.removeEventListener('keydown', this.keydown, true);
			return this.stop();
		}
	}

	statusData(e) {
		const id = this.model.get('status.id');
		return this.model.root.get(`items.${id}.data`);
	}

	sharedComment(statusId, commentPath) {
		//@model.root.get "items.#{statusId}.#{commentPath}"
		const x = this.model.root.get(`items.${statusId}.${commentPath}`);
		if (x) {
			x.status = 'published';
			return x;
		}
	}

	hideLinkHoverCard() {
		return this.model.del('hovercard');
	}

	showLinkHoverCard(el) {
		return () => {
			return this.model.set('hovercard', {hash: el.dataset.hash, top: el.offsetTop - 150 });
		};
	}

	setupLinkHoverCards() {
		if (this.dom != null ? this.dom.querySelectorAll : undefined) {
			const els = this.dom.querySelectorAll('.blog-body a');
			if (els != null ? els.length : undefined) {
				return (() => {
					const result = [];
					for (var el of Array.from(els)) {
						if (!el.dataset.hset) {
							el.dataset.hset = '1';
							el.addEventListener('mouseenter', this.showLinkHoverCard(el));
							result.push(el.addEventListener('mouseleave', this.hideLinkHoverCard));
						} else {
							result.push(undefined);
						}
					}
					return result;
				})();
			}
		}
	}

	noww() { return +(new Date()); }

	showMore(e) {
		e.preventDefault();
		e.stopPropagation();
		return this.model.set('showMore', true);
	}
	
	showEditedList(e) {
		return this.model.set('showEditedList', true);
	}

	showEdit(e, id) {
		e.preventDefault();
		return this.model.root.set('_page.showEdit', { id });
	}

	showLess(status) {
		return this.model.del('showMore');
	}
		
	fromMe() {
		return this.model.get('statusreal.from') === this.model.root.get('_session.userId');
	}

	canShare() {
		return this.page.profile.isConfirmed() && this.page.profile.isEnabled();
	}

	canDel() {
		return (this.model.get('statusreal.from') === this.model.root.get('_session.userId')) && this.page.profile.isConfirmed() && this.page.profile.isEnabled();
	}

	canEdit() {
		return (this.model.get('statusreal.from') === this.model.root.get('_session.userId')) && this.page.profile.isConfirmed() && this.page.profile.isEnabled();
	}

	isAdmin() {
		return this.model.root.get('_p.user_pvt.admin');
	}

	edit() {
		return this.page.emit('editStatus', this.model.get('statusreal'));
	}

	reply() {
		this.model.set('showcomments', true);
		return this.model.set('replying', true);
	}

	canLike() {
		return (this.model.get('status.from') !== this.model.root.get('_session.userId')) && this.page.profile.isConfirmed() && this.page.profile.isEnabled();
	}

	like(e) {
		e.preventDefault();
		e.stopPropagation();
		const userId = this.model.root.get('_session.userId');
		const statusId = this.model.get('statusreal.parent') || this.model.get('statusreal.id');
		const readcount = this.model.get('readcount');

		const liked = this.model.get('readcount.liked');
		const o = { id: statusId };

		if (this.model.get('statusreal.type') === 'comment') {
			o.cp = this.model.get('statusreal.path');
		}

		// unlike
		if (liked) {
			return util.toServer(this.model.root.at('_p.methods'), 'unlike', o);
		// like
		} else {
			return util.toServer(this.model.root.at('_p.methods'), 'like', o);
		}
	}


	go(e) {

		const inpath = function(el, nodeNames, classes) {
			const contains = function(list) {
				if (list == null) { list = []; }
				for (var item of Array.from(list)) {
					if (Array.from(classes).includes(item)) { return true; }
				}
			};

			while (el) {
				if (Array.from(nodeNames).includes(el.nodeName) || contains(el.classList)) { return true; }
				el = el.parentNode;
			}
		};

		// if !e.target?.classList.contains('tag') and !e.target?.classList.contains('task') and !@model.get('single')
		// console.log e.target, inpath(e.target, ['A', 'IMG'], ['tag', 'k-p-overlay', 'dl-container', 'play-button'])
		if (!this.model.get('single') && !inpath(e.target, ['A', 'IMG'], ['tag', 'k-p-overlay', 'dl-container', 'play-button'])) {
			e.preventDefault();
			e.stopPropagation();

			const parent = this.model.get('status.parent');
			const status = this.model.get('statusreal');
			const el = document.getElementById('status-single');

			// if we are on a single-status page, we don't want to use this go() function
			// or if we didn't find the status...
			if (el || !status || __guard__(window.getSelection(), x => x.toString())) {
				return;
			}

			const statusId = status.id;
			this.model.set('going', 'status-going-to');
			return util.redirect(this.app, this.page, '/status/' + (parent ? `${parent}#c-${statusId}` : statusId));
		}
	}
}


app.component('status', StatusHome);
app.component('status:status', Status);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}