/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const util = require('../util');

class AdminUsernames {
	approve(user) {
		return util.toServer(this.model.root.at('_p.methods'), 'approve-username', { id: user.id }, function() {});
	}

	decline(user) {
		return util.toServer(this.model.root.at('_p.methods'), 'decline-username', { id: user.id }, function() {});
	}

	toggleRealUser(id) {
		console.log(id);
		return util.toServer(this.model.root.at('_p.methods'), 'toggle-real-user', { id });
	}
}

module.exports = AdminUsernames;
