/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class StatusListInfinity {

	constructor() {
		this.cancel = this.cancel.bind(this);
	}

	show(e, status, which) {
		const {
            model
        } = this;

		this.q = model.root.graph(which, { vertex: `items/${status.id}`, $limit: 10, $orderby: { ts: 1 } });
		this.q.subscribe(err => {
			let u;
			const users = this.q.get() || [];

			let ids = ((() => {
				const result = [];
				for (u of Array.from(this.q.get() || [])) { 					result.push(u.id);
				}
				return result;
			})());
			util.updateUseridsPlain.call(this.model.root, ids);

			model.ref('users', this.q);
			model.set('show', 1);
			return model.on('insert', 'users', (index, arr) => {
				ids = ((() => {
					const result1 = [];
					for (u of Array.from(arr || [])) { 						result1.push(u.id);
					}
					return result1;
				})());
				return util.updateUseridsPlain.call(this.model.root, ids);
			});
		});

		return false;
	}

	cancel(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.q.unsubscribe();
		return this.model.del('show');
	}
}


app.component('status-list-inf', StatusListInfinity);
