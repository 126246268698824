/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class StatusFlag {

	constructor() {
		this.cancel = this.cancel.bind(this);
		this.keydown = this.keydown.bind(this);
	}

	show(e, status, readcount, commentPath) {

		// if status.path is set, this status is a flagged comment 
		if (commentPath == null) { commentPath = ''; }
		if ((status != null ? status.path : undefined) && !commentPath) {
			commentPath = status.path;
		}

		// If status.parent is set, this status is actually a comment
		// and the status.parent refers to the status, that is comment
		// is attached to.
		// In this case we want to use the status' id, not the comment's id.
		const statusId = status.parent || status.id;

		// path to the array that holds the user id's who have flagged this status/comment
		this.path = `items.${statusId}${commentPath ? '.' + commentPath : ''}.flagged`;
		this.sharedM = this.model.root.at(this.path);
		this.model.set('commentPath', commentPath);
		this.model.set('text', '');
		this.model.set('status', status);
		this.model.set('readcount', readcount);
		this.model.set('isFlagged', this.isFlagged());
		if (this.textarea) {
			this.textarea.focus();
		}
		return false;
	}

	cancel(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		return this.model.del('status');
	}

	keydown(ev) {
		if (((ev != null ? ev.ctrlKey : undefined) || (ev != null ? ev.metaKey : undefined)) && ((ev != null ? ev.keyCode : undefined) === 13)) {
			return this.submit(ev);
		}
	}

	isFlagged() {
		return this.model.get("readcount.flagged");
	}

	flag() {
		const user = this.model.root.at('_p.user');
		const status = this.model.get('status');
		const statusId = status.parent || status.id;
		const text = this.model.get('text');
		const commentPath = this.model.get('commentPath');

		const o = { id: statusId, text };
		if (commentPath) {
			o.comment = commentPath;
		}
		util.toServer(this.model.root.at('_p.methods'), 'flag', o, this.cancel);

		return this.model.toast('success', this.app.proto.t.call(this.app, 'Ok, flagged'));
	}

	submit(e) {
		e.preventDefault();
		e.stopPropagation();
		return this.flag();
	}
}


app.component('status-flag', StatusFlag);
