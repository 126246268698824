/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const util2 = require('aamu-racer/lib/util');
const util = require('../util');

var Helpers = (function() {
	let timeDifference = undefined;
	Helpers = class Helpers {
		constructor() {
			this.setAllSeen = this.setAllSeen.bind(this);
			this.itemStatusChanged = this.itemStatusChanged.bind(this);
			this.itemRemoved = this.itemRemoved.bind(this);
			this.itemChanged = this.itemChanged.bind(this);
			this.itemInserted = this.itemInserted.bind(this);
			this.notifChanged = this.notifChanged.bind(this);
			this.notifInserted = this.notifInserted.bind(this);
			this.notifFeedUpdated = this.notifFeedUpdated.bind(this);
			this.setUnseenItems = this.setUnseenItems.bind(this);
			this.setUnseenCount = this.setUnseenCount.bind(this);
			this.connectionStateChanged = this.connectionStateChanged.bind(this);
			this.followChanged = this.followChanged.bind(this);
			this.sharesInsert = this.sharesInsert.bind(this);
			this.videoPlayed = this.videoPlayed.bind(this);
			this.touch = this.touch.bind(this);
			this.gotMsg = this.gotMsg.bind(this);
			this.timerF = this.timerF.bind(this);
			this.tasksRegistrationConfirmEmailResult = this.tasksRegistrationConfirmEmailResult.bind(this);
		}

		static initClass() {
			timeDifference = 0;
			this.prototype.name = 'helpers';
		}

		destroy() {
			clearInterval(this.timer);
			this.model.root.removeAllListeners('insert', '_p.user.msg');
			this.model.root.removeAllListeners('insert', '_page.shares');
			this.model.root.removeAllListeners('insert', '_p.shares');
			this.model.root.removeAllListeners('insert', '_p.follow');
			this.model.root.removeAllListeners('remove', '_p.follow');
			this.model.root.removeAllListeners('change', '$connection.state');

			this.model.root.removeAllListeners('remove', '_p.items');
			this.model.root.removeAllListeners('change', '_p.items.*.status');
			this.model.root.removeAllListeners('insert', '_p.items');

			this.model.root.removeAllListeners('change', '_p.notifs.*');
			this.model.root.removeAllListeners('insert', '_p.notifs');

			return this.page.removeListener('setAllSeen', this.setAllSeen);
		}

		init(model) {
			if (util2.isServer) {
				if (__guard__(__guard__(__guard__(this.page != null ? this.page.req : undefined, x2 => x2.headers), x1 => x1['user-agent']), x => x.match(/(Windows Phone)|(XBLWP)|(ZuneWP)|(Lumia)/))) {
					return model.root.set('_page.isWin', 'is-win');
				}
			}
		}


		create(model, dom) {
			model.root.set('_page.showTime', true);
			model.root.on('change', '_p.user.tasks.confirmation-email', function() { return this.tasksRegistrationConfirmEmailResult.apply(this, arguments); }.bind(this));

			window.m = model.root;
			this.timer = setInterval(this.timerF, 30000);
			setTimeout(this.timerF, 100);
			this.model.root.on('insert', '_p.user.msg', this.gotMsg);

			// touch the session every hour to prevent it from being deleted on the server
			setInterval(this.touch, 60 * 60 * 1000);
			this.model.root.on('insert', '_page.shares', this.sharesInsert);
			this.model.root.on('insert', '_p.shares', this.sharesInsert);

			// when we start/stop following someone, we need to update everything (or at least 'items')
			this.model.root.on('insert', '_p.follow', this.followChanged);
			this.model.root.on('remove', '_p.follow', this.followChanged);

			this.model.root.on('change', '$connection.state', this.connectionStateChanged);

			this.model.root.on('remove', '_p.items', this.itemRemoved);
			this.model.root.on('change', '_p.items.*.status', this.itemStatusChanged);
			this.model.root.on('insert', '_p.items', this.itemInserted);
			this.model.root.on('change', '_p.items.*', this.itemChanged);

			this.model.root.on('change', '_p.notifs.*', this.notifChanged);
			this.model.root.on('insert', '_p.notifs', this.notifInserted);

			this.createReadcountDocs();

			return this.page.on('setAllSeen', this.setAllSeen);
		}

		setAllSeen() {
			this.model.root.del('_p.unseencount');
			return this.model.root.set('_p.unseen', {});
		}
			// @removeFromChannelUnreads()

		itemStatusChanged(index, val)  {
			if (val === 'deleted') {
				return this.model.remove('items', index);
			}
		}

		itemRemoved(index, arr) {
			if (arr) {
				return Array.from(arr).map((item) =>
					this.model.root.del(`_p.unseen.${item.id}`));
			}
		}

		itemChanged(index, item) {
			util.updateAllFromPosts.call(this.model.root, this.model.root.get('_session.userId'), [item]);
			return this.itemInserted(index, [item]);
		}

		itemInserted(index, arr) {
			util.updateAllFromPosts.call(this.model.root, this.model.root.get('_session.userId'), arr);
			return setTimeout(this.setUnseenItems(index, arr), 100);
		}

		notifChanged(index, item) {
			return util.updateAllFromNotifs.call(this.model.root, [item]);
		}

		notifInserted(index, arr) {
			return util.updateAllFromNotifs.call(this.model.root, arr);
		}

		// notif feed updated
		// some notification update timestamp is probably changed
		// let's refresh the feed again so that we gave the correct feed order
		notifFeedUpdated(index, ids) {
			return console.log('notifFeedUpdated', arguments);
		}

		setUnseenItems(index, arr) {
			return () => {
				// if these went to the end of the list (as opposed to the front), we don't treat them as unseen, because they are from infinitescroll
				// everything else is set as unseen
				const items = this.model.root.get('_p.items') || [];

				if (index < 10) {
					util.setUnseen.call(this.model.root, arr, this.disconnectedTime);
				}

				return this.setUnseenCount();
			};
		}

		setUnseenCount() {
			const items = this.model.root.get("_p.unseen") || {};
			const c = __guard__(Object.keys(items), x => x.length);

			if (c) {
				return this.model.root.set("_p.unseencount", c);
			} else {
				return this.model.root.del("_p.unseencount");
			}
		}

		// set the time we got disconnected
		// this is for determining wheter to set items unseen when we connect again
		connectionStateChanged(state) {
			if (state === 'disconnected') {
				return this.disconnectedTime = Date.now();
			}
		}

		createReadcountDocs() {
			const ids = this.model.root.get('_p.readcountids') || [];

			const sub = id => {
				const d = this.model.root.at(`readcount.${id}`); 
				return d.subscribe(err => {
					if (!d.get()) {
						return this.model.root.add("readcount", { id });
					}
			});
			};

			return (() => {
				const result = [];
				for (var id of Array.from(ids)) {
					var doc = this.model.root.get(`readcount.${id}`);
					if (!doc) {
						result.push(sub(id));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}

		muted(id) {
			const arr = this.model.root.get('_p.mute') || [];
			const found = (Array.from(arr).filter((x) => id === (x != null ? x.id : undefined)).map((x) => x.id));
			return found.length;
		}

		followChanged(idx, arr) {
			return this.model.root.rpc('all', function() {});
		}

		sharesInsert(idx, arr) {
			return util.updateAllFromPosts.call(this.model.root, this.model.root.get('_p.user.id'), arr);
		}

		videoPlayed(o) {
			return this.videoIsPlaying = o;
		}

		touch() {
			return util.toServer(this.model.root.at('_p.methods'), 'touch', {});
		}

		gotMsg(index, arr) {
			const msg = arr != null ? arr[0] : undefined;

			if (msg != null ? msg.type : undefined) {
				this.model.toast(msg.type, msg.msg);
				return this.model.root.remove('_p.user.msg', index);
			}
		}

		remaining(suspended) {
			if (suspended) {
				return this.round(((suspended.days * 24 * 60 * 60 * 1000) - (Date.now() - suspended.when))/1000/60/60/24, 2);
			}
		}

		round(number, precision) { 
			const factor = Math.pow(10, precision);
			const tempNumber = number * factor;
			const roundedTempNumber = Math.round(tempNumber);
			return roundedTempNumber / factor;
		}

		timerF(e) {
			return this.model.root.set('_page.timer', Date.now());
		}

		tasksRegistrationConfirmEmailResult(result) {
			if ((result != null ? result.success : undefined) != null) {
				return this.model.toast(((result != null ? result.success : undefined) ? 'success' : 'error'), result.msg);
			}
		}

		inArray(id, list) { return id && (list != null ? list.length : undefined) && Array.from(list).includes(id); }
	};
	Helpers.initClass();
	return Helpers;
})();

module.exports = Helpers;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}