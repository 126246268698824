/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/threads', function(page, model, params, next) {
	model.set('_page.statusids', model.getCopy('_p.usermisc.unreads'));
	const items = model.query('items2', '_page.statusids');
	const userId = model.get("_p.user.id");
	return model.execute('participated-threads', { v0: `auths/${userId}` }, function(err, data) {
		model.set('_page.followingStatusIds', (Array.from(data).map((d) => d.id)));
		util.updateAllFromPosts.call(model, userId, data);
		const subscribesItems = model.query('items2', '_page.followingStatusIds');
		return model.subscribe(subscribesItems, items, function(err) {
			model.ref('_page.subscribedItems', subscribesItems);
			model.ref('_page.items', items);
			return page.render('threads');
		});
	});
});

