/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Threads {

	constructor() {
		this.unreadsInserted = this.unreadsInserted.bind(this);
	}

	init(model) {
		model.ref('items', model.root.at('_page.items'));
		model.ref('subscribedItems', model.root.at('_page.subscribedItems'));
		return this.model.root.removeAllListeners('insert', '_p.usermisc.unreads');
	}

	create(model) {
		model.ref('statusids', model.root.at('_page.statusids'));
		return model.root.on('insert', '_p.usermisc.unreads', this.unreadsInserted);
	}

	unreadsInserted(index, ids) {
		const statusids = this.model.get('statusids') || [];
		if (ids != null ? ids.length : undefined) {
			return (() => {
				const result = [];
				for (var id of Array.from(ids)) {
					if (!Array.from(statusids).includes(id)) {
						result.push(this.model.unshift('statusids', id));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	}

	markAllRead() {
		const items = this.model.get('items');
		if (items) {
			const ids = (Array.from(items).map((item) => item.id));
			return util.toServer(this.model.root.at('_p.methods'), 'set-comments-read', { ids });
		}
	}
}


app.component('threads', Threads);
