/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Channels {

  block(event) {
    event.preventDefault();

    let channel = this.model.get('channel');
    if ((channel != null ? channel.charAt(0) : undefined) === '#') {
      channel = channel.substring(1);
    }

    this.model.del('channel');
    
    const blockedc = this.model.root.at('_p.user.blockedc');
    const arr = blockedc.get() || [];

    if (channel && !Array.from(arr).includes(channel)) {
      return blockedc.push(channel);
    }
  }

  unblock(channel) {
    this.model.del('newStatus');
    const blockedc = this.model.root.at('_p.user.blockedc');

    if (channel) { 
      const idx = __guard__(blockedc.get(), x => x.indexOf(channel));
      if (idx !== -1) {
        return blockedc.remove(idx);
      }
    }
  }
}



app.component('channels', Channels);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}