/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Video {

	// we are leaving the page. Store video source/location if we are playing thevideo
	constructor() {
		this.loadstart = this.loadstart.bind(this);
		this.setupVideo = this.setupVideo.bind(this);
	}

	destroy() {
		if (this.video) {
			if (!this.video.paused || this.model.get('small')) {
				return this.model.root.set('_misc.videoplaying', { 
					status: this.model.get('status'),
					time: this.video.currentTime,
					id: this.vid_id,
					paused: this.video.paused
				});
			}
		}
	}
			//else if @vid_id is @model.root.get '_misc.videoplaying.id'
			//	console.log 'del videoplaying', @vid_id
			//	@model.root.del '_misc.videoplaying'

	create() {
		this.model.set('client', 1);
		this.vid_id = this.model.get('status.id');

		// are we coming back to original video page?
		// if so, let's start playing from the location we last were.
		if (this.model.get('single') && (this.vid_id === this.model.root.get('_misc.videoplaying.id'))) {
			this.model.set('position', this.model.root.get('_misc.videoplaying.time'));

			// we don't want to show the video in the sidebar
			return this.model.root.del('_misc.videoplaying');
		}
	}


	deleteVideo() {
		return this.model.del('status.videopv');
	}

	HlsIsSupported() {
		if (typeof window !== 'undefined') {
			const mediaSource = (window.MediaSource = window.MediaSource || window.WebKitMediaSource);
			const sourceBuffer = (window.SourceBuffer = window.SourceBuffer || window.WebKitSourceBuffer);
			const isTypeSupported = mediaSource && (typeof mediaSource.isTypeSupported === 'function') && mediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
			// if SourceBuffer is exposed ensure its API is valid
			// safari and old version of Chrome doe not expose SourceBuffer globally so checking SourceBuffer.prototype is impossible
			const sourceBufferValidAPI = !sourceBuffer || (sourceBuffer.prototype && (typeof sourceBuffer.prototype.appendBuffer === 'function') && (typeof sourceBuffer.prototype.remove === 'function'));
			return isTypeSupported && sourceBufferValidAPI;
		}
	}

	publishVideoInFuture() {
		return util.toServer(this.model.root.at('_p.methods'), 'publish-video-in-future', { id: this.model.get('status.id')});
	}

	loadstart() {
		if (this.model.get('position')) {
			try {
				if (this.hlsplayers && 0) {
					this.hlsplayers[0].play();
					return this.hlsplayers[0].seek(this.model.get('position'));
				} else {
					this.video.currentTime = this.model.get('position');
					return this.video.play();
				}
			} catch (err) {
				return console.log(err);
			}
		}
	}

	publishVideo() {
		return util.toServer(this.model.root.at('_p.methods'), 'publish-video', { id: this.model.get('status.id')});
	}

	setupVideo(which, which2, video) {
		if (!video) { return; }

		if (!(video != null ? video.mf : undefined)) {
			console.error('video empty, shouldnt happen', which, video);
			console.trace();
			return;
		}

		//return if @set.video is which + video.mf

		if ((typeof plyr === 'undefined') || (typeof Hls === 'undefined')) {
			return;
		}

		if (__guard__(plyr.get(this.video), x => x.length)) { return; }

		// already setup?
		if (this.hlsplayers) { return; }

		// @video.addEventListener 'loadedmetadata', -> console.log 'loadedmetadata', arguments

		if (Hls.isSupported()) {
			const hls = new Hls({ autoStartLoad: false, startLevel: video.levels - 1 });
			hls.loadSource(video.mf);
			hls.attachMedia(this.video);
			this.video.addEventListener('loadstart', this.loadstart);
			this.video.addEventListener('play', () => hls.startLoad());
				//util.toServer @model.root.at('_p.methods'), 'increase-viewcount-video', { id: @model.get('status.id')}

			const controls = [
				'<button type="button" data-plyr="play" class="plyr__play-large">',
						'<svg><use xlink:href="#plyr-play" /></svg>',
						'<span class="plyr__sr-only">Play</span>',
				'</button>', 
				"<div class='plyr__controls'>",
					"<button type='button' data-plyr='play'>",
							"<svg><use xlink:href='#plyr-play'></use></svg>",
							"<span class='plyr__sr-only'>Play</span>",
					"</button>",
					"<button type='button' data-plyr='pause'>",
							"<svg><use xlink:href='#plyr-pause'></use></svg>",
							"<span class='plyr__sr-only'>Pause</span>",
					"</button>",
					"<span class='plyr__progress'>",
							"<label for='seek{id}' class='plyr__sr-only'>Seek</label>",
							"<input id='seek{id}' class='plyr__progress--seek' type='range' min='0' max='100' step='0.1' value='0' data-plyr='seek'>",
							"<progress class='plyr__progress--played' max='100' value='0' role='presentation'></progress>",
							"<progress class='plyr__progress--buffer' max='100' value='0'>",
									"<span>0</span>% buffered",
							"</progress>",
							"<span class='plyr__tooltip'>00:00</span>",
					"</span>",
					"<span class='plyr__time'>",
							"<span class='plyr__sr-only'>Current time</span>",
							"<span class='plyr__time--current'>00:00</span>",
					"</span>",
					"<span class='plyr__time'>",
							"<span class='plyr__sr-only'>Duration</span>",
							"<span class='plyr__time--duration'>00:00</span>",
					"</span>",
					"<button type='button' data-plyr='mute'>",
							"<svg class='icon--muted'><use xlink:href='#plyr-muted'></use></svg>",
							"<svg><use xlink:href='#plyr-volume'></use></svg>",
							"<span class='plyr__sr-only'>Toggle Mute</span>",
					"</button>",
					"<span class='plyr__volume'>",
							"<label for='volume{id}' class='plyr__sr-only'>Volume</label>",
							"<input id='volume{id}' class='plyr__volume--input' type='range' min='0' max='10' value='5' data-plyr='volume'>",
							"<progress class='plyr__volume--display' max='10' value='0' role='presentation'></progress>",
					"</span>",
					"<button type='button' data-plyr='captions'>",
							"<svg class='icon--captions-on'><use xlink:href='#plyr-captions-on'></use></svg>",
							"<svg><use xlink:href='#plyr-captions-off'></use></svg>",
							"<span class='plyr__sr-only'>Toggle Captions</span>",
					"</button>",
					"<button type='button' data-plyr='logo'>",
							`<a target='_blank' title='View on Chttr.co' href='/status/${this.model.get('status.id')}'><img src='/img/chttr-logo.svg'></a>`,
					"</button>",
					"<button type='button' data-plyr='fullscreen'>",
							"<svg class='icon--exit-fullscreen'><use xlink:href='#plyr-exit-fullscreen'></use></svg>",
							"<svg><use xlink:href='#plyr-enter-fullscreen'></use></svg>",
							"<span class='plyr__sr-only'>Toggle Fullscreen</span>",
					"</button>",
			"</div>"].join("");

			if (this.model.get('logo')) {
				return this.hlsplayers = plyr.setup(this.video, { disableContextMenu: false, html: controls });
			} else { 
				return this.hlsplayers = plyr.setup(this.video, { disableContextMenu: false, displayDuration: true, controls: (this.model.get('small') ? [ 'play', 'progress', 'volume', 'fullscreen'] : ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'fullscreen']) });
			}
		}
	}
}

app.component('video:video', Video);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}