/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const UTIL = require('../util');
const app = require('../index');

const ignoreUrls = [ '/logout', '/logout/', '/profiili/email-change', '/profiili/email-change/', '/edit-profile/choose', '/edit-profile/choose/'];

const redirect1 = function(app, page, url) {
  try {
    if (__guard__(app != null ? app.history : undefined, x => x.push)) {
      return app.history.push(url);
    } else if (__guard__(page != null ? page.res : undefined, x1 => x1.redirect)) {
      return page.res.redirect(url);
    }
  } catch (err) {
    return console.error('[ util.redirect ]', url, ':', err);
  }
};

const handleUserReg = function(page, model, params, next) {
  let isLoggedIn = model.get('_session.loggedIn');
  const logout = function(s) {
    console.log(s);
    const redirect2 = function(server) {
      if (server) {
        return redirect1(app, page, '/logout', false);
      } else {
        // a small hack since the framework doesn't use windows.location bu
        // pushState and that won't work as we need to go to the server
        return window.location.href = '/logout';
      }
    };

    if (typeof window === 'undefined') {
      //model.flash 'error', s
      //model.toast 'error', s
      //setTimeout (-> redirect2(true)), 5000
      redirect2(true);
      // don't call next() because that will cause 'headers already sent' error
      // todo: find out why and fix this
      return;
    } else {
      model.toast('error', s);
      return setTimeout(redirect2, 5000);
    }
  };

  const gotrpc = function() {
    const userId = model.get('_session.userId');
    isLoggedIn = model.get('_session.loggedIn');
    // console.log 'userId', userId

    if (!userId) {
      if (isLoggedIn) {
        return logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (1)');
      }
      return next();
    }

    const user = model.root.get('_p.user');    
    // console.log { userId, user, isLoggedIn }

    // if a user is set, USERS.isLoggedIn should return true
    // if it doesn't, we are in a middling state and we should log out
    if ((user != null ? user.id : undefined) && !isLoggedIn) {
      return logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (2)');
    } else if (!user && isLoggedIn) {
      return logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (3)');
    } else if (__guard__(user != null ? user.local : undefined, x => x.status) === 'deleted') {
        return logout('Sorry, this account has been disabled.');
    } else {
      return next();
    }
  };

  if (model.get('_p.user')) {
    return gotrpc();
  } else {
    return model.rpc('all', gotrpc);
  }
};


// must be the first route
app.get('*', handleUserReg);
app.post('*', handleUserReg);


function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}