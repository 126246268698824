/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const profileCommon = require('./profile.common');
const util = require('../util');

const AdminUsernames = require('./admin-usernames');

class ProfileCard {
  static initClass() {
    this.prototype.bg = profileCommon.bg;
  }
}
ProfileCard.initClass();

class ProfileStatuses {}

class Profile {
  constructor() {
    this.cancelFlag = this.cancelFlag.bind(this);
  }

  static initClass() {
    this.prototype.bg = profileCommon.bg;
  }

  startchat(e, id) {
    return console.log(id);
  }

  toggleMute(e, id) {
    e.preventDefault();
    const isMuted = this.isMuted(id);
    if (isMuted) {
      return util.toServer(this.model.root.at('_p.methods'), 'unmute', { id });
    } else {
      return util.toServer(this.model.root.at('_p.methods'), 'mute', { id });
    }
  }

  isMuted(id) {
    const arr = this.model.root.get('_p.mute') || [];
    const found = (Array.from(arr).filter((x) => id === (x != null ? x.id : undefined)).map((x) => x.id));
    return found.length;
  }

  unverify(e, id) {
    e.preventDefault();
    return util.toServer(this.model.root.at('_p.methods'), 'unverify-user', { id });
  }

  verify(e, id) {
    e.preventDefault();
    return util.toServer(this.model.root.at('_p.methods'), 'verify-user', { id });
  }

  flag(e, profile) {
    return this.model.set("flag", { id: profile.id });
  }

  submitFlag(event) {
    event.preventDefault();
    const user = this.model.root.at('_p.user');
    const text = this.model.get('text');
    const o = { id: this.model.get("flag.id"), text: this.model.get('text') };
    util.toServer(this.model.root.at('_p.methods'), 'flag-user', o, this.cancelFlag);
    return this.model.toast('success', 'Ok, flagged');
  }

  cancelFlag(event) {
    return this.model.del("flag");
  }
}
Profile.initClass();

class FollowButton {

  toggleFollow(id) {
    const isFollowing = this.isFollowing(id);

    if (isFollowing) {
      return util.toServer(this.model.root.at('_p.methods'), 'unfollow', { id });
    } else {
      return util.toServer(this.model.root.at('_p.methods'), 'follow', { id });
    }
  }

  isFollowing(id) {
    const f = this.model.root.get("_p.follow") || [];
    const found = (Array.from(f).filter((x) => (x != null ? x.id : undefined) === id).map((x) => id));
    return found.length;
  }
}

app.component('public-profile:user-card', ProfileCard);
app.component('public-profile:profile-full', Profile);
app.component('public-profile:profile-full-statuses', ProfileStatuses);
app.component('edit-profile:info', Profile);
app.component('public-profile:follow-button', FollowButton);
