/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Admin {

	suspend(e) {
		e.preventDefault();
		return util.toServer(this.model.root.at('_p.methods'), 'suspend-user', { 
			id: this.model.get('user.id'),
			days: this.model.get('days1'),
			why: this.model.get('why1'),
			pw: this.model.get('pw1')
		});
	}

	unsuspend(e) {
		e.preventDefault();
		return util.toServer(this.model.root.at('_p.methods'), 'unsuspend-user', { 
			id: this.model.get('user.id'),
			pw: this.model.get('pw1')
		});
	}

	delete(e) {
		e.preventDefault();
		return util.toServer(this.model.root.at('_p.methods'), 'delete-user', { 
			id: this.model.get('user.id'),
			why: this.model.get('why2'),
			pw: this.model.get('pw2')
		});
	}

	undelete(e) {
		e.preventDefault();
		return util.toServer(this.model.root.at('_p.methods'), 'undelete-user', { 
			id: this.model.get('user.id'),
			pw: this.model.get('pw2')
		});
	}
}

app.component('admin-profile:profile-admin', Admin);
