/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');
const profileCommon = require('./profile.common');
const Conf = require('kansalaiskeskustelu-conf').client;
const conf = new Conf();

const AdminUsernames = require('./admin-usernames');

class EditProfile {}

class EditProfileSettingsUsername {
	create() {
		return this.model.root.on('change', '_page.chooseUsername', () => util.validateUsername.call(this, this.model.root.get('_page.chooseUsername')));
	}
}

class EditProfileSettingsSome {
	constructor() {
		this.changed = this.changed.bind(this);
	}

	create() {
		return this.model.root.on('change', '_p.user.local.settings.*', this.changed);
	}

	changed(which, val) {
		return util.toServer(this.model.root.at('_p.methods'), 'toggle-some-sub', { which, val });
	}
}


class EditProfileSettingsNotifications {
	toggleOnlyFriends() {
		const val = this.model.root.get('_p.user.local.settings.notif_only_friends');
		return this.model.root.set('_p.user.local.settings.notif_only_friends', !val);
	}
}

class EditProfileDelete {

	delete(e) {
		e.preventDefault();
		return util.toServer(this.model.root.at('_p.methods'), 'delete-user', { 
			id: this.model.root.get('_p.user.id'),
			why: this.model.get('why'),
			pw: this.model.get('pw')
		});
	}
}

app.component('edit-profile:info', EditProfile);
app.component('edit-profile:settings-username', EditProfileSettingsUsername);
app.component('edit-profile:settings-some', EditProfileSettingsSome);
app.component('edit-profile:settings-delete-account', EditProfileDelete);
app.component('edit-profile:settings-admin-usernames', AdminUsernames);

