/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Channel {
  constructor() {
    this.cancelStatusInput = this.cancelStatusInput.bind(this);
  }

  static initClass() {
  
    this.prototype.isHomescreen = {
      get() { if (__guard__(this.getHashtagsub(), x => x.home)) { return true; } else { return false; } },
      set() {}
    };
  }

  create(model) {
    this.model.root.on('insert', '_p.usermisc.hashtagur', () => {
      model.root.set(`_p.usermisc.hashtag.${this.tag}`, 0);
      return this.removeFromUnreads();
    });

    return this.page.on('cancelStatusInput', this.cancelStatusInput);
  }

  init(model) {
    this.tag = model.root.get('_page.tag');
    model.set('tag', this.tag);
    //model.set 'default', "##{@tag} "
    // todo (arango): model.root.del "_p.usermisc.hashtag.#{@tag}"
    model.root.set(`_p.usermisc.hashtag.${this.tag}`, 0);
    return this.removeFromUnreads();
  }

  removeFromUnreads() {
    const hashtagur = this.model.root.get("_p.usermisc.hashtagur");
    if (hashtagur != null ? hashtagur.length : undefined) {
      const idx = hashtagur.indexOf(this.tag);
      if (idx !== -1) {
        return this.model.root.remove("_p.usermisc.hashtagur", idx);
      }
    }
  }
		
  getHashtagsub() {
      const list = this.model.root.get('_p.usermisc.hashtagsub') || [];
      const tag = this.model.get('tag');
      for (var l of Array.from(list)) {
        if (tag === (l != null ? l.tag : undefined)) {
          return l;
        }
      }
    }

  cancel() {
    return this.model.del('newStatus');
  }

  addNewStatus() {
    return this.model.set('newStatus', true);
  }

  cancelStatusInput(e) { return this.model.del('newStatus'); }

  isSubscribed() { return this.getHashtagsub(); }

  toggleHomescreen() {
    const list = this.model.root.get('_p.usermisc.hashtagsub') || [];
    const tag = this.model.get('tag');
    return (() => {
      const result = [];
      for (let i = 0; i < list.length; i++) {
        var l = list[i];
        if (tag === (l != null ? l.tag : undefined)) {
          result.push(this.model.root.set(`_p.usermisc.hashtagsub.${i}.home`, (l.home ? 0 : 1)));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  toggleSubscribe(el) {
    if (this.isSubscribed()) {
      return this.unsubscribe();
    } else {
      return this.subscribe();
    }
  }

  subscribe() {
    return util.toServer(this.model.root.at('_p.methods'), 'hashtagsub', { tag: this.model.get('tag') });
  }

  unsubscribe() {
    return util.toServer(this.model.root.at('_p.methods'), 'hashtagunsub', { tag: this.model.get('tag') });
  }
}
Channel.initClass();


// dirty way to create a setter for this function
Channel.prototype.isSubscribed.set = function() {};

app.component('channel', Channel);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}