/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Register {
  static initClass() {
  
    this.prototype.validateUsername = util.validateUsername;
  }
  init(model) {
    this.model.root.on('change', '_page.flash.register.username', () => this.validateUsername(this.model.root.get('_page.flash.register.username')));
    return this.model.root.on('change', '_page.chooseUsername', () => this.validateUsername(this.model.root.get('_page.chooseUsername')));
  }

  submit(e) {
    return console.log(e);
  }
}
Register.initClass();

app.component('register', Register);
