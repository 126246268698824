/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/video/:id', (page, model, params, next) => model.rpc('status', { id: params.id }, function(err) {
  if (!model.get('_page.item')) {
    return page.render('status:notfound');
  } else {
    return page.render('video');
  }
}));
