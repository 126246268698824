/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');


app.get('/channel/:tag', function(page, model, params, next) {
	const channel = params.tag.toLowerCase();
	const notificationQ = model.notification('notificationsc', params.tag.toLowerCase());
	return model.subscribe(notificationQ, function(err) {  
		model.ref('_page.notificationc', notificationQ);
		const queryObject = { $limit: 50, $orderby: { created: -1 }, status: 'published', hashtags: { $inflipped: channel } };
		const items = model.query('items2', queryObject);
		return model.fetch(items, function(err) {
			model.ref('_page.itemsfetched', items);
			util.updateAllFromPosts.call(model, model.get('_p.user.id'), items.get());
			model.set('_page.feed', (Array.from(items.get() || []).map((item) => item.id)));
			const items2 = model.query('items2', '_page.feed');
			return model.subscribe(items2, function(err) {
				model.ref('_page.items', items2);
				model.set('_page.tag', channel);
				return page.render('channel');
			});
		});
	});
});

