/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class StatusDelete {

	constructor() {
		this.cancel = this.cancel.bind(this);
	}

	show(e, id) {
		this.model.set('deleteDialog', { id });
		return false;
	}

	cancel(e) {
		this.preventDefault(e);
		return this.model.del('deleteDialog');
	}

	delete(e) {
		this.preventDefault(e);
		const deleteDialog = this.model.get('deleteDialog');
		return util.toServer(this.model.root.at('_p.methods'), 'delete-status', { id: deleteDialog.id }, this.cancel);
	}

	preventDefault(e) {
		if (e) {
			e.preventDefault();
			return e.stopPropagation();
		}
	}
}

app.component('status-delete', StatusDelete);
