/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/chat', (page, model, params, next) => model.rpc('allchats', { }, err => page.render('chat:all')));

app.get('/chat/:id', function(page, model, params, next) {
	if (params.id.match(/^@/)) {
		return model.rpc('startchat', { username: params.id.substring(1) }, function(err) {
			if (model.get('_page.chaturl')) {
				return util.redirect(app, page, `/chat/${model.get('_page.chaturl')}`);
			} else {
				return page.render('chat:notfound');
			}
		});
	} else {
		return model.rpc('chat', { id: params.id }, err => page.render('chat'));
	}
});
