/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

app.get('/twitter/:statusId', function(page, model, params, next) {
	model.set('_page.statusId', params.statusId);
	const item = model.at(`items.${params.statusId}`);
	return item.fetch(function(err) {
		if (err) {
			console.error(err);
			model.set('_page.error', err);
			return page.render('twitter');
		} else if (!item.get()) {
			model.set('_page.error', 'Post not found.');
			return page.render('twitter');
		} else {
			return page.res.redirect(`https://twitter.com/intent/tweet?text=${encodeURIComponent(item.get('data'))}`);
		}
	});
});

