/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class StatusList {

	constructor() {
		this.scrollHandler = this.scrollHandler.bind(this);
		this.removeHashtagFromUnreads = this.removeHashtagFromUnreads.bind(this);
		this.removeFromChannelUnreads = this.removeFromChannelUnreads.bind(this);
		this.decreaseHashtagUnread = this.decreaseHashtagUnread.bind(this);
		this.setupMutationObserver = this.setupMutationObserver.bind(this);
	}

	destroy(model) {
		return document.removeEventListener('scroll', this.scrollHandler);
	}

	create(model) {

		this.setupMutationObserver(document.getElementById('status-list'));
		document.addEventListener('scroll', this.scrollHandler);
		return this.overflowAnchorEnabled = window.getComputedStyle(document.body).overflowAnchor === 'auto';
	}

		// if @model.get 'main'
		// 	@model.root.on 'insert', '_p.usermisc.hashtagur', @removeFromChannelUnreads

	init(model) {}
		// if @model.get 'main'
		// 	@removeFromChannelUnreads()

	scrollHandler() {
		const changed = [];

		if (window.scrollY === 0) { 
			return this.page.emit('setAllSeen');
		}

		const items = this.model.root.get("_p.unseen") || {};

		return (() => {
			const result = [];
			for (var itemId in items) {
				if (util.inViewport(document.getElementById(`s-${itemId}`))) {
					this.model.root.del(`_p.unseen.${itemId}`);
					this.model.root.increment("_p.unseencount", -1);
					result.push(changed.push(itemId));
				} else {
					result.push(undefined);
				}
			}
			return result;
		})();
	}

		// if changed.length
		// 	@decreaseHashtagUnread changed


	removeHashtagFromUnreads(hashtag) {
		const hashtagur = this.model.root.get('_p.usermisc.hashtagur') || [];

		const idx = hashtagur.indexOf(hashtag);
		if (idx !== -1) {
			this.model.root.remove("_p.usermisc.hashtagur", idx);
			return this.model.root.del(`_p.usermisc.hashtag.${hashtag}`, idx);
		}
	}

	// if we are on the homepage and a channel status arrives
	// and we have subscribed to this channel and 
	// added this channel to our feed (home page), then
	// we want to mark the channel as read.
	removeFromChannelUnreads() {
		const hashtagsub = this.model.root.get('_p.usermisc.hashtagsub') || [];
		const hashtagur = this.model.root.get('_p.usermisc.hashtagur') || [];

		if (hashtagsub != null ? hashtagsub.length : undefined) {
			return (() => {
				const result = [];
				for (var channel of Array.from(hashtagsub)) {
					if (channel != null ? channel.home : undefined) {
						result.push(this.removeHashtagFromUnreads(channel.tag));
					} else {
						result.push(undefined);
					}
				}
				return result;
			})();
		}
	}

	// determine, what hashtags still have undread statuses
	// remove those hashtags from unread lists that don't have unread statuses anymore.
	// This has to be "decrease" and plain "set" because our subscribed hashtag may receive
	// statuses that aren't put into our feed - but our feed can also contain statuses that 
	// aren't subscribed into our feed, if we have followed a user. Thus, we need a "decrease"
	// and not a "set".
	// In practice we do some setting here, but this can be called like it would be decreasing
	// - when a particular status has be "seen", i.e. changed from unseen to seen.
	decreaseHashtagUnread(arr) {
		const unseen = this.model.root.get('_p.unseen');
		const items = this.model.root.get('_p.items');
		const hashtags = { };

		// calculate which hashtags have how many unseen statuses
		for (var id of Array.from(arr)) {
			var item = this.model.root.get(`items.${id}`) || this.model.root.get(`items2.${id}`);
			if (__guard__(item != null ? item.hashtags : undefined, x => x.length)) {
				for (var ht of Array.from(item.hashtags)) {
					hashtags[ht] = hashtags[ht] || 0;
					hashtags[ht]++;
				}
			}
		}

		// set the correct count to each hashtag, remove the hashtags that don't have any unread statuses anymore
		return (() => {
			const result = [];
			for (var hashtag in hashtags) {
				var decreaseByThisAmount = hashtags[hashtag];
				var oldValue = this.model.root.get(`_p.usermisc.hashtag.${hashtag}`);
				if (oldValue && decreaseByThisAmount) {
					var newValue = oldValue - decreaseByThisAmount;
					if (newValue === 0) {
						result.push(this.removeHashtagFromUnreads(hashtag));
					} else { 
						result.push(this.model.root.set(`_p.usermisc.hashtag.${hashtag}`, newValue));
					}
				} else {
					result.push(undefined);
				}
			}
			return result;
		})();
	}


	setupMutationObserver(target) {
		const me = this.model.root.get('_p.user.id');

		const scrollr = node => {
			return () => this.scrollRightAmount(node);
		};

		const observer = new MutationObserver(mutations => {
			return mutations.forEach(mutation => {
				if (__guard__(mutation != null ? mutation.addedNodes : undefined, x => x.length)) {
					for (var node of Array.from((mutation != null ? mutation.addedNodes : undefined))) {
						// this should be the first LI child of the target node (UL)
						//if node.nodeName is 'LI' and node.parentNode is target
						//  console.log node
						//if node.nodeName is 'LI' and node.parentNode is target and node.dataset?.from isnt me and node.classList.contains('unseen')
						if ((node.nodeName === 'LI') && (node.parentNode === target)) {
							var b = node.getBoundingClientRect();
							if (((node.dataset != null ? node.dataset.from : undefined) === me) && (window.pageYOffset < 5)) {
								this.model.root.del(`_p.unseen.${node.id.substring(2)}`);
							} else if ((node.nodeName === 'LI') && (node.parentNode === target) && ((b.top < 65) || (node === node.parentNode.firstElementChild))) {
								//console.log node
								if (this.overflowAnchorEnabled && (b.top < 0)) { return; }
								this.scrollRightAmount(node);

								var imgs = node.querySelectorAll('img');
								if (imgs != null ? imgs.length : undefined) {
									for (var img of Array.from(imgs)) {
										img.addEventListener('load', scrollr(node));
									}
								}
							}
						}
					}
				}
			});
		});

		if (target) {
			return observer.observe(target, { childList: true, subtree: true });
		}
	}

	// scroll when a new status arrivess
	scrollRightAmount(el) {
		const from = (el.h || 0);
		let b = el.getBoundingClientRect();
		//h = b.height + 1.8 - from
		const h = b.height - from;
		el.h = b.height;
		if (h) {
			window.scrollBy(0, h);
			return b = el.getBoundingClientRect();
		}
	}
}
			//console.log el, el is el.parentNode.firstElementChild, b.top, window.pageYOffset


app.component('status-list', StatusList);
module.exports = StatusList;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}