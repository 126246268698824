/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/uusi', (page, model, params, next) => // in case we are 
page.render('status-input'));

app.post('/uusi', function(page, model, params, next) {
	console.log(params);
	return page.render('status-input');
});

app.get('/muokkaa/:id', function(page, model, params, next) {
	const item = model.at(`items.${params.id}`);
	return item.subscribe(function(err) {
		model.ref('_page.item', item);
		return page.render('status-input:edit');
	});
});
